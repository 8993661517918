.calcs{
    min-height: 100vh;
    background-image: url('.//../../../assets/Backgrounds/Pink\ Gradient2.png');
    background-repeat: no-repeat;
    background-size: cover;
/* 
    position: relative;
    top: 0;
    bottom:0;
    left: 0; */

}
.conatiner{
   position: absolute;
    top: -5000px;
}