.loader{
    height: 50px;
    width: 200px;
    z-index: 100;
    position: fixed;

}

.circle{
    border-radius: 50%;
    background-color: black;
    height: 50px;
    width: 50px;
    position: absolute;

}
.multiply{
    left: 0px;
    background-color: #e6007e ;
    animation: multiply 0.5s infinite;
}
    @keyframes multiply{
    
        0%{
            transform: translateY(0px);
        }
        50%{
            transform: translateY(-25px);
        }
        100%{
            transform: translateY(-0px);
        }
    }
.divide{
    background-color: #ffcc01 ;
    left: 55px;
    animation: divide 0.5s infinite;
    animation-delay: 50ms;
}
    @keyframes divide{
        0%{
            transform: translateY(0px);
        }
        50%{
            transform: translateY(-25px);
        }
        100%{
            transform: translateY(-0px);
        }
    }
.add{
    background-color: #10c96d ;
    left: 110px;
    animation: add 0.5s infinite;
    animation-delay: 100ms;
}
    @keyframes add{
        0%{
            transform: translateY(0px);
        }
        50%{
            transform: translateY(-25px);
        }
        100%{
            transform: translateY(-0px);
        }
}
.subtract{
    background-color: #0082c7 ;
    left: 165px;
    animation: subtract 0.5s infinite;
    animation-delay: 150ms;
}
    @keyframes subtract{
        0%{
            transform: translateY(0px);
        }
        50%{
            transform: translateY(-25px);
        }
        100%{
            transform: translateY(-0px);
        }
}

.calculator{
    height: 150px;
    width: 115px;
  
    position: fixed;
   
    border-radius: 5%;
    top: 30%;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -30%)
}

.reveal{
 
  
    position: absolute;
    left: 5px;
    bottom: -40px;
    color: black;
    font-size: 15px;
    padding: 5px;
    font-weight: 500;
    border-radius: 5%;
}

.circle2{
    border-radius: 20%;

    height: 50px;
    width: 50px;
    position: absolute;
    justify-content: center;
    align-content: center;
    color: whitesmoke;
    font-size: 40px;
    font-weight: 700;
    animation: circle2 0.5s infinite ; 
    padding-bottom: 10px;
  

}
@keyframes circle2{
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(-4px);
    }
    100%{
        transform: translateY(-0px);
    }
}

.multiply2{
    left: 5px;
    top: 40px;
    background-color: #e6007e ;
    display: flex;
    flex-wrap: wrap;

  
}
   
.divide2{
    background-color: #ffcc01 ;
    top: 40px;
    left: 60px;
    display: flex;
    flex-wrap: wrap;
    animation-delay: 100ms;
    
    
}
  
.add2{
    background-color: #10c96d ;
    left: 5px;
    bottom: 5px;
    display: flex;
    flex-wrap: wrap;
  
    animation-delay: 150ms;

}
   
.subtract2{
    background-color: #0082c7 ;
    bottom: 5px;
    left:  60px;
    display: flex;
    flex-wrap: wrap;
    animation-delay: 250ms;
    
}
 