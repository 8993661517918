#nav{    
    box-shadow: 0 0 2px 2px rgba(0, 0, 0 , 0.3);
    height: 97px;
    width: auto;
    border-image: linear-gradient(90deg ,#e6007e, #ffcc01, #10c96d, #00B5E2) 1;
    border-bottom: 3px solid;
    color: black;
    z-index: 10;
    
        }

#end{
  justify-content: end;
}
  #logo{ 
    color: #3a4e84;
    justify-content: start;
  }
  .hover-underline-animation {
    display:grid;
    position: relative;
    color: #0087ca;
    overflow: hidden;
    
    
  }
  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 5px;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg ,#e6007e, #ffcc01, #10c96d, #0082c7);
    /* background:  red; */
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    
  }
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

/*Calculate*/
 .hover-underline-animation2 {
   display: inline-block;
   position: relative;
   color: #0087ca;

 }
 .hover-underline-animation2::after {
   content: '';
   position: absolute;
   width: 100%;
   transform: scaleX(0);
   height: 3.5px;
   bottom: 0;
   margin-bottom: -3px;
   left: 0;
   border-radius: 500px;
   background: linear-gradient(90deg ,#e6007e, #ffcc01, #10c96d, #00B5E2); 
   /* background: #1d1f8c; */
   transform-origin: bottom right;
   transition: transform 0.25s ease-out;

 }

 .hover-underline-animation2:hover::after {
   transform: scaleX(1);
   transform-origin: bottom left;

   
 }

 .hover-underline-animation3 {
  display: inline-block;
  position: relative;
  color: red;
}
.hover-underline-animation3::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  /* background: linear-gradient(90deg ,#e6007e, #ffcc01, #10c96d, #0082c7); */
  background:  red;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  
}
.hover-underline-animation3:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
button.navbar-toggler.collapsed {
  background-color: white;

 }
button.navbar-toggler {
      background-color: white;
}

.brand{
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 10;
}
.card2{
  position: relative;
  overflow: hidden;
  display:grid;
  box-shadow: 0 5px 10px 4px rgb(27, 27, 27, 0.5);
  text-align: start;
  margin: 0px 10px 0px 10px;
  max-width: 100%;
  
  
}

.card img{
  width: 100%;
}

.intro2{
  width: 100%;
  height: 5vh;
  position: absolute;
  color: white;
  box-sizing: border-box;
  bottom: 0;
  background-color: rgb(29, 31, 140, 0.5);
  transition: .5;
  padding-left: 5px;
  line-height: 1.2;
  
}

.intro2 h1{
  margin: 1px;
  font-size: 1.5rem;
}

.intro2 p{
  margin-top: 3px;
  visibility: hidden;
  font-size: 13px;
}

.card2:hover{
  transform: scale(1.05) ;
  cursor: pointer;
}

.card2:hover .intro2{
  height: auto;
  background-color: rgb(29, 31, 140, 0.5);
 
}
.card2:hover p{
  visibility: visible;
  opacity: 1;
}

.card2:hover img{
  transform: scale(1.09) rotate(-4deg);
}

.gradient-border-bottom {
  position: relative;
  display: inline-block; 
}

.gradient-border-bottom::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px; /* Border thickness */
  background: linear-gradient(90deg, #e6007e, #ffcc01, #10c96d, #0082c7);
}

.activeCard{
  transform: scale(1.07);
}

.expandNav{
  transform-origin: 500px;
}

.expandNav{
  z-index: 5;
  animation: expandNav 0.4s 1;
  animation-fill-mode: forwards;
  animation-delay: -1ms;
}
  @keyframes expandNav{
      0%{
         height: 97px;
      }
      100%{
        height: 450px;
      }
}
.collapseNav{
  z-index: 5;
  animation: collapseNav 0.4s 1;
  animation-fill-mode: forwards;
}
  @keyframes collapseNav{
      0%{
         height: 450px;
      }
      100%{
        height: 97px;
      }
}
.animateButton{
  animation: animateButton 0.4s 1;
  animation-fill-mode: forwards;
}
  @keyframes animateButton{
   0%{
     top: 80px;
   }
   100%{
     top: 435px;
   }
}

.animateButton2{
  animation: animateButton2 0.4s 1;
  animation-fill-mode: forwards;
}
  @keyframes animateButton2{
   0%{
     top: 435px;
   }
   100%{
     top: 80px;
   }
}

.animateImage{
  position: absolute;
  top: -400px;
  animation: animateImage 0.6s 1;
  animation-delay: 0.1ms;
  animation-fill-mode: forwards;
}
  @keyframes animateImage{
   0%{
     top: -400px;
   }
   100%{
     top: 90px;
   }
}

.animateImage2{
  position: absolute;
  top: 90px;
  animation: animateImage2 0.6s 1;
  animation-delay: 0.1ms;
  animation-fill-mode: forwards;
}
  @keyframes animateImage2{
   0%{
     top: 90px;
   }
   100%{
     top: -400px;
   }
}

.cover{
  width: 100%;
  height: 85px;
  background-color: white;
  position: absolute;
  z-index: 1;
  top: 0;

}

.link{
  text-decoration: none;
  justify-self: center;
  justify-items: center;
  display: flex;
  text-align: center;
  margin-top: 10px;
}

.link h1{
  color: #1d1f8c;
  width: 300px;
}

@media (max-width: 991px) {
  .card2 {
    display: none; /* Hide images on mobile */
  }

  .link {
    display: block; /* Show links on mobile */
    margin-left: 0px;
  }

}

@media (min-width: 992px){
  .card2 {
    display: block; /* Show images on desktop */
    width: auto;
    height: auto;
  }
  .link {
    display: none; /* Hide links on desktop */
  }
}

@media (min-width: 925px) and (max-width: 1366px ) and (orientation: portrait) {
  .card2 {
    display: block; /* Show images on desktop */
    width: auto;
    height: auto;
    
  }
  .card2 img{
    width: 200px;
  }
  .intro2{
    height: 3vh;
  }
  
  .link {
    display: none; /* Hide links on desktop */
  }
}

@media (min-width: 925px) and (max-width: 1094px ) and (orientation: landscape) {
  .card2 {
    display: block; 
    width: auto;
    height: auto;
    
  }
  .card2 img{
    width: 216px;
  }
  
  .link {
    display: none; 
  }
}

@media (min-width: 768px) and (max-width: 924px ) {
  .card2 {
    display: block; 
    width: auto;
    height: auto;
    
  }
  .card2 img{
    width: 161px;
    min-height: 250px;
    height: auto;
  }
  .intro2 h1{
    font-size: 1.2rem;
  }
  .intro2{
    height: 4vh;
  }
  
  .link {
    display: none; 
  }
}
