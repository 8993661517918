
.collapse-select {
  margin-left: 20px;
}

@media (max-width: 987px) {
    .collapse-select {
    width: 100%;
    margin-left: 0px;
    }
    .collapse-select .ant-select-selection-item {
      display: none; 
    }
    .ant-select-dropdown {
      max-width: 300px; 
      white-space: nowrap; 
    }

    .collapse-select .ant-select-arrow {
      color: #1d1f8c;
      position: absolute;
      margin-right: 23px;
    }
}


.foot {
  text-decoration: none;
  color: white
}
.foot a{
  text-decoration: none;
  color: white
}

.foot:hover{
  text-decoration: none;
  color: #ffcc01;
  cursor: pointer;
}

.foot a:hover{
  text-decoration: none;
  color: #ffcc01;
  cursor: pointer;
}
.foot a:visited{
  text-decoration: none;
  color: white
}
.foot a:active{
  text-decoration: none;
  color: white
}

.scroll{
  /* max-height: 80vh; */
  overflow-y: auto;
}

.responsive-footer-right{
  justify-content: end;
}
.responsive-footer-center{
  justify-content: center;
}
.responsive-footer-left{
  justify-content: start;
}
.content {
  width: 50vw;
  justify-content: center;
  justify-self: center;
  
}

@media (max-width: 1000px){
  .responsive-footer-right{
    margin-top: 20px;
    text-align: center;
    justify-content: center;
  }
  .responsive-footer-center{
    text-align: center;
    justify-content: center;
  }
  .responsive-footer-left{
    text-align: center;
    justify-content: center;
  }
  .content {
    width: 100vw;
    justify-content: center;
    
  }

}
