/* ul {
    list-style: none;
  }
  
  ul li::before {
    content: '✓';
    color: green;
    font-size: 1rem;
  } */
.card{
  display: flex;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 5px 10px 4px rgb(27, 27, 27, 0.5);
  text-align: start;
  
}

.card img{
  width: 100%;
}

.intro{
  width: 100%;
  height: 5vh;
  position: absolute;
  color: white;
  box-sizing: border-box;
  bottom: 0;
  background-color: rgb(29, 31, 140, 0.5);
  transition: .5;
  padding-left: 5px;
  align-self: center;
 
  
}

.intro h1{
  margin: 5px;
  font-size: 1.5rem;
}

.intro p{
  margin: 4px;
  visibility: hidden;
  opacity: 0;
}

.card:hover{
  transform: scale(1.05) ;
  cursor: pointer;
}

.card:hover .intro{
  height: auto;
  background-color: rgb(29, 31, 140, 0.5);
 
}
.card:hover p{
  visibility: visible;
  opacity: 1;
}

.card:hover img{
  transform: scale(1.1) rotate(-5deg);
}

@media (max-width: 500px){
  .card {
    width: 90%;
  }
}
@media (min-width: 501px) and (max-width: 1000px){
  .card {
    width: 80%;
  }
}
@media (min-width: 201px) and (max-width: 721px) and (orientation: landscape){
  .card {
    width: 60%;
  }
  .intro{
    height: 8vh;
  }
}
@media (min-width: 990px) and (max-width: 1282px) and (orientation: landscape) {
  .intro h1{
    font-size: 1rem;
  }
}

.creditBox{
  display: flex;
  flex-flow: column; 
  width: 90%;
  height: 40vh;
  margin: 10px;
  padding: 0px 0px 15px 0px;
 
  box-shadow: 2px 2px 6px 3px rgba(0, 0, 0 , 0.3) ; 
   
}
.row{
  justify-content: center;
  bottom: 5px;
  
}
.creditBox1:hover{
    border: 3px solid #1d1f8c; 
    box-shadow: 2px 2px 8px 3px #1d1f8c ; 
}

.creditBox2:hover{
  border: 3px solid rgba(193,116,38,1); 
  box-shadow: 2px 2px 8px 3px rgba(193,116,38,1) ; 
}

.creditBox3:hover{
  border: 1px solid #AFAFAF; 
  box-shadow: 2px 2px 15px 5px rgba(160,160,160,0.6);
}

.creditBox4:hover{
  border: 4px solid #f3a924 ; 
  box-shadow: 2px 2px 15px 6px #f3a924  ; 
}

.creditBox5:hover{
  border: 4px solid; 
  border-image: linear-gradient(130deg ,#e6007e, #ffcc01, #10c96d, #00B5E2) 1;
  box-shadow: 2px 2px 15px 6px silver ; 
}

.innerBox{
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  min-width: 60%;
  min-height: 100%;
  justify-content: center;
  display: grid;

}

