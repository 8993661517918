/* h5 {
    position: relative;
    background: linear-gradient(to right, #e6007e, #ffcc01, #10c96d, #0082c7);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textAnimate 5s linear infinite;
}

@keyframes textAnimate {
    0% {
        background-size: 0%;
    }
    100% {
        background-size: 500% ;
    }
} */

/* h5 {
    position: relative;
    font-size: 36px; 
    overflow: hidden; 
    text-transform: uppercase;
}

h5::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    height: 105%;
    background: linear-gradient(to right,black, #e6007e, #ffcc01, #10c96d, #0082c7);
    background-image: url("img_tree.gif") ;
    -webkit-text-fill-color: transparent; 
    overflow: hidden; 
    animation-delay: 3s;
    animation: textAnimate 5s ease infinite;
   
}

@keyframes textAnimate {
    0%{
        background-position-x: 0%;
    }
    100%{
        background-position-x: 155%;
    }
} */


.textAnimate{
 
    display: inline-block;
    background: linear-gradient(to right,black 0%, #e6007e, #ffcc01, #10c96d, #0082c7,black 55%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    background-clip: text;
    color: transparent;
    height: 85%;
    -webkit-animation: textAnimate 2.8s ease-out infinite;
    animation: textAnimate 2.8s ease-out infinite;
    background-size: 200% 3000px;
    color: blue
  }
  
  @keyframes textAnimate {
    0% {
        background-position: 100% 0;
      }
      100% {
        background-position: -100% 0;
      }
  }
  